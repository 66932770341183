<template>
  <div>
    <v-row class="align-center">
      <v-col md="3">
        <app-text-search-field
          @input="handleSearchInput($event)"
          :loading="isLoading"
        />
      </v-col>
      <v-col md="3">
        <company-select
          chips
          multiple
          v-model="searchParams.company_id"
          :item-value="(val) => val.id"
          :itemText="(val) => val.short_name"
          solo
          flat
          background-color="grey lighten-4"
          :dense="false"
          @change="select(1)"
        />
      </v-col>
      <v-col md="2">
        <v-text-field
          hide-details="auto"
          type="date"
          solo
          flat
          background-color="grey lighten-4"
          v-model="searchParams.due_date_start"
          label="Vencimento Inicial"
          @change="select(1)"
        />
      </v-col>
      <v-col md="2">
        <v-text-field
          hide-details="auto"
          type="date"
          solo
          flat
          background-color="grey lighten-4"
          v-model="searchParams.due_date_end"
          label="Vencimento Final"
          @change="select(1)"
        />
      </v-col>
      <v-col class="text-right" md="2">
        <v-btn
          class="text-capitalize"
          bottom
          large
          color="primary"
          v-if="$acl.can('billPayableCreateEdit')"
          @click="handleInsertBillPayable()"
          >Novo
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3">
        <AccountPlanSelect
          v-model="searchParams.account_plan_id"
          :item-value="(val) => val.id"
          :dense="false"
          solo
          flat
          background-color="grey lighten-4"
          multiple
          clearable
          @change="select(1)"
        />
      </v-col>
      <v-col md="5">
        <v-chip-group
          v-model="searchParams.status"
          multiple
          mandatory
          @change="select(1)"
        >
          <v-chip filter outlined value="foreseen"> Previsão </v-chip>
          <v-chip filter outlined value="to_pay"> A Pagar </v-chip>
          <v-chip filter outlined value="paid"> Pago </v-chip>
          <v-chip filter outlined value="canceled"> Cancelado </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col md="3">
        <v-card color="primary" elevation="0" dark>
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total
              <br />
              <b class="text-h5">{{
                $format.decimal(billPayablesSum.value_sum)
              }}</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3">
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Previsão
              <br />
              <b class="text-h5">0,00</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3">
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Previsão
              <br />
              <b class="text-h5">0,00</b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3">
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Total Selecionado
              <small class="text--secondary" v-if="selectedCount > 0">
                ({{ selectedCount }})</small
              >
              <br />
              <b class="text-h5">{{ $format.decimal(selectedSum) }}</b>
            </div>
            <div>
              <v-btn
                :disabled="!canMakePayment"
                class="text-capitalize"
                color="primary"
                @click="handlePayBillPayable()"
              >
                Pagar
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="billPayables.data"
          :items-per-page="-1"
          :show-select="$acl.can('payBillPayable')"
          v-model="selected"
          :options.sync="options"
          hide-default-footer
          @click:row="handleBillPayableRowClick($event)"
          @update:options="handleTableUpdate($event)"
        >
          <template v-slot:[`item.company`]="{ item }">
            <company-chip :company="item.company" />
          </template>
          <template v-slot:[`item.supplier_description`]="{ item }">
            <span v-if="item.supplier">{{ item.supplier.name }}</span>
            <span v-if="item.supplier && item.description"> | </span>
            {{ item.description }}
          </template>
          <template v-slot:[`item.value`]="{ item }">
            {{ $format.decimal(item.value) }}
          </template>
          <template v-slot:[`item.net_paid_value`]="{ item }">
            {{ $format.decimal(item.net_paid_value) }}
          </template>
          <template v-slot:[`item.due_date`]="{ item }">
            <app-due-date-label :due-date="item.due_date" />
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <bill-payable-status-chip :value="item.status" />
          </template>

          <!-- <template v-slot:item.empresa="{ item }">
            <v-chip small>{{ item.company.name }}</v-chip>
          </template> -->
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="searchParams.page"
            :length="billPayables.last_page"
            @input="select($event)"
            :total-visible="9"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <InsertBillPayableForm
      ref="InsertBillPayableForm"
      @store="select(searchParams.page)"
    />
    <UpdateBillPayableForm
      ref="UpdateBillPayableForm"
      @store="select(searchParams.page)"
    />
    <PayBillPayableForm
      ref="PayBillPayableForm"
      @store="handlePaymentStore()"
    />
    <BillPayableDialog ref="BillPayableDialog" />
  </div>
</template>

<script>
import CompanyChip from "@/components/company/sections/CompanyChip.vue";
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import AccountPlanSelect from "@/components/account-plan/ui/AccountPlanSelect.vue";
import BillPayableStatusChip from "@/components/bill-payable/sections/BillPayableStatusChip.vue";
import InsertBillPayableForm from "@/components/bill-payable/forms/InsertBillPayableForm";
import UpdateBillPayableForm from "@/components/bill-payable/forms/UpdateBillPayableForm";
import BillPayableDialog from "@/components/bill-payable/sections/BillPayableDialog";
import PayBillPayableForm from "@/components/bill-payable/forms/PayBillPayableForm";

export default {
  components: {
    CompanyChip,
    CompanySelect,
    AccountPlanSelect,
    BillPayableStatusChip,
    InsertBillPayableForm,
    UpdateBillPayableForm,
    BillPayableDialog,
    PayBillPayableForm,
  },

  data() {
    return {
      headers: [
        { text: "Empresa", value: "company", sortable: false },
        {
          text: "Fornecedor / Descrição",
          value: "supplier_description",
          sortable: false,
        },
        {
          text: "Plano de contas",
          value: "account_plan.name",
          sortable: false,
        },
        { text: "Documento", value: "document" },
        { text: "Valor", value: "value", align: "right" },
        { text: "V.Pago", value: "net_paid_value", align: "right" },
        { text: "Vencimento", value: "due_date", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],

      options: {},

      searchParams: {
        company_id: [],
        account_plan_id: [],
        text: null,
        page: 1,
        due_date_start: null,
        due_date_end: null,
        status: ["to_pay"],
        orderBy: null,
        orderDirection: null,
      },

      billPayables: {
        data: [],
        page: 1,
        per_page: 1,
      },

      billPayablesSum: {},

      selected: [],

      isLoading: false,
    };
  },

  mounted() {
    this.select(1);
  },

  computed: {
    selectedSum() {
      return this.$calc.sum(this.selected, "value");
    },

    selectedCount() {
      return this.selected.length;
    },

    canMakePayment() {
      let nonPayaybleBills = this.selected.filter(
        (row) => row.status != "to_pay"
      );

      return this.selectedCount > 0 && nonPayaybleBills.length == 0;
    },

    // value
    // paid_value
    // discount
    // addition
    // interest
    // net_paid_value
  },

  methods: {
    // sum(arr, field) {
    //   return arr.reduce((sum, row) => sum + parseFloat(row[field]), 0);
    // },

    async select(page = 1) {
      this.searchParams.page = page;
      this.options.page = page;
      this.isLoading = true;
      await this.$http
        .index("bill-payable/bill-payable", this.searchParams)
        .then((response) => {
          this.billPayables = response.billPayables;
          this.billPayablesSum = response.billPayablesSum;
        });
      this.isLoading = false;
    },

    handleTableUpdate(options) {
      this.options = options;
      this.searchParams.orderBy = options.sortBy[0];
      this.searchParams.orderDirection = options.sortDesc[0] ? "desc" : "asc";
      this.select(options.page);
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleInsertBillPayable() {
      this.$refs.InsertBillPayableForm.open();
    },

    handlePayBillPayable() {
      this.$refs.PayBillPayableForm.open(this.selected);
    },

    handlePaymentStore() {
      this.selected = [];
      this.select(this.searchParams.page);
    },

    handleBillPayableRowClick(billPayable) {
      if (this.hasPayments(billPayable)) {
        this.$refs.BillPayableDialog.open(billPayable.id);
      } else {
        if (this.$acl.can("billPayableCreateEdit")) {
          this.$refs.UpdateBillPayableForm.open(billPayable.id);
        }
      }
    },

    hasPayments(billPayable) {
      return billPayable.paid_value != 0;
    },
  },
};
</script>

<style>
</style>